// PAGES
export const PAGE_DASHBOARD = "PAGE_DASHBOARD";
export const PAGE_MOLECULAR = "PAGE_MOLECULAR";
export const PAGE_CLINICAL = "PAGE_CLINICAL";
export const PAGE_AVATAR = "PAGE_AVATAR";
export const PAGE_TERMS = "PAGE_TERMS";
export const PAGE_USER_MANUALS = "PAGE_USER_MANUALS";
export const PAGE_RELEASE_NOTES = "PAGE_RELEASE_NOTES";
export const PAGE_ACCOUNT = "PAGE_ACCOUNT";

// EXTERNAL APPLICATIONS
export const APP_IMV4 = "APP_IMV4";
export const APP_IMV2 = "APP_IMV2";
export const APP_CBIOPORTAL = "APP_CBIOPORTAL";
export const APP_DNANEXUS = "APP_DNANEXUS";
export const APP_ORIENX = "APP_ORIENX";

// ROLES
export const ROLE_ORIEN = "ROLE_ORIEN";
export const ROLE_PHARMA = "ROLE_PHARMA";
export const ROLE_M2GEN = "ROLE_M2GEN";

// COMPONENTS & TOOLS
export const SUMMARY_BOXES = "SUMMARY_BOXES";
export const FEEDBACK = "FEEDBACK";
export const TOOLBOX = "TOOLBOX";
