import { Ability, AbilityBuilder } from "@casl/ability";
import * as subjects from "./abilitySubjects";

// This will build the abilities (permissions) for a given user
const updateAbility = (ability, user) => {
  const { can: allow, cannot: forbid, rules } = AbilityBuilder.extract();

  if (!user) {
    ability.update();
    return;
  }

  const { company, userType, hasLimitedAccess, hasinformatics, hascbioPortal, hasDnaNexus, hasORIENx, hasToolBox } = user;

  // Everyone can view these
  allow("view", subjects.PAGE_TERMS);
  allow("view", subjects.PAGE_USER_MANUALS);
  allow("view", subjects.PAGE_RELEASE_NOTES);
  allow("view", subjects.PAGE_ACCOUNT);
  allow("view", subjects.FEEDBACK);

  // userType Abilities
  if (userType === "PHARMA") {
    allow("view", subjects.ROLE_PHARMA);
  }

  if (userType === "ORIEN") {
    allow("view", subjects.ROLE_ORIEN);
  }

  if (userType === "M2GEN") {
    allow("view", subjects.ROLE_M2GEN);
  }

  if (hasinformatics) allow("view", subjects.APP_IMV2);
  if (hascbioPortal) allow("view", subjects.APP_CBIOPORTAL);
  if (hasDnaNexus) allow("view", subjects.APP_DNANEXUS);
  if (hasORIENx) allow("view", subjects.APP_ORIENX);
  if (hasToolBox && userType === "ORIEN") allow("view", subjects.TOOLBOX);

  if (!hasLimitedAccess) {
    allow("view", subjects.SUMMARY_BOXES);
    allow("view", subjects.PAGE_DASHBOARD);
    allow("view", subjects.PAGE_MOLECULAR);
    allow("view", subjects.PAGE_CLINICAL);
    allow("view", subjects.PAGE_AVATAR);
  } else {
    forbid("view", subjects.SUMMARY_BOXES);
    forbid("view", subjects.PAGE_DASHBOARD);
    forbid("view", subjects.PAGE_MOLECULAR);
    forbid("view", subjects.PAGE_CLINICAL);
    forbid("view", subjects.PAGE_AVATAR);
  }

  // --- Apply the updates --- //
  ability.update(rules);
};

// Defines hot to detect object's type
const subjectName = item => {
  if (!item || typeof item === "string") {
    return item;
  }
  return item.__type;
};

const ability = new Ability([], { subjectName });

export { ability, updateAbility, subjects };
